import { isDevelopment } from 'wcz-layout';

// let apiUrl: string = "http://localhost:8080";
let apiUrl: string = "https://api.dev.wistron.eu/sot";

if (!isDevelopment) {
    apiUrl = "https://api.wistron.eu";
}

const departmentsUrl = "https://api.dev.wistron.eu/ps/v1/department?search=departmentId=in=(CWZE10,CWZE11,CWZE12,CWZE13,CWZE20,CWZE21,CWZE22,CWZE23,CWZE30,CWZ610,CWZ620)&fields=departmentId,description";
const psEmployeesUrl = "https://api.wistron.eu/ps/v1/employee?search=departmentId=in=(CWZE10,CWZE11,CWZE12,CWZE13,CWZE20,CWZE21,CWZE22,CWZE23,CWZ620);(position==*Leader*,position==*Super*,position==*Manager*)&fields=employeeId,nameTitleCase,position";

//export microservices base URL
export { apiUrl, departmentsUrl, psEmployeesUrl };