import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useMemo } from "react";
import Department from "../../models/Department";
import Project from "../../models/Project";
import { useGetDepartments } from "../../queries/DepartmentQueries";
import { useDeleteProject, useUpdateProject } from "../../queries/ProjectSettingsQueries";
import WarningText from "../common/WarningText";

interface EditProjectDialogProps {
    readonly project: Project,
    readonly projectToEdit: Project,
    readonly setProjectToEdit: (project: Project) => void,
}

export default function EditProjectDialog(props: EditProjectDialogProps) {
    const { project, projectToEdit, setProjectToEdit } = props;

    const { data: departmentList = [] } = useGetDepartments();

    const { mutate: editProject } = useUpdateProject();
    const { mutate: deleteProject } = useDeleteProject();

    const handleClickCancelDialog = () => {
        setProjectToEdit({} as Project);
    };

    const canEdit = useMemo(() => !!projectToEdit.id && !!projectToEdit.name && !!projectToEdit.departmentId, [projectToEdit]);

    function onEdit(){
        if (!canEdit){ return; }
        editProject({
            ...projectToEdit,
            changeTracker: {
                oldDepartmentId: project.departmentId,
                oldProjectName: project.name
            }
        }, { onSuccess: handleClickCancelDialog });
    }

    function onDelete(){
        const shouldDelete = window.confirm(`Are you sure that you want to delete the project?`);
        if (!shouldDelete) { return; }
        deleteProject(projectToEdit.id, { onSuccess: handleClickCancelDialog })
    }

    return (
        <Dialog open={!!projectToEdit.id} fullWidth>
            <DialogTitle>Edit project</DialogTitle>

            <DialogContent>
                <TextField
                    variant="standard"
                    margin="dense"
                    id="name"
                    label={<>Project name<span style={{ color: "red" }}>*</span></>}
                    type="text"
                    value={projectToEdit.name}
                    fullWidth
                    onChange={(event) => {
                        setProjectToEdit({ ...projectToEdit, name: event.target.value });
                    }}
                />
                <FormControl variant="standard" fullWidth sx={{ mt: '8px', mb: '4px' }}>
                    <InputLabel id="departmentId">Department<span style={{ color: "red" }}>*</span></InputLabel>
                    <Select
                        margin="dense"
                        id="departmentId"
                        type="text"
                        value={projectToEdit.departmentId}
                        fullWidth
                        onChange={(event) => {
                            setProjectToEdit({ ...projectToEdit, departmentId: event.target.value });
                        }}
                    >
                        {departmentList.map((department: Department) => <MenuItem key={department.departmentId} value={department.departmentId}>{department.departmentId} - {department.description}</MenuItem>)}
                    </Select>
                </FormControl>

                <Box mt={2}>
                    <WarningText text="The project name will be automatically updated in the Headcount Summary table." mb={1} />
                    <WarningText text="The project name will be automatically updated for all records in the current month in the Daily Workload table." mb={1} />
                    <WarningText text="Changing the department ID or deleting this project will result in removing it from all employees in the Headcount Summary table. Please assign a new project, a new line and a new station to the affected employees." />
                </Box>
            </DialogContent>

            <DialogActions sx={{ justifyContent: "space-between", mx: 1.5 }}>
                <Button onClick={onDelete} color="error">Delete</Button>

                <Box>
                    <Button onClick={handleClickCancelDialog}>Cancel</Button>
                    <Button disabled={!canEdit} variant="contained" onClick={onEdit}>Save</Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
