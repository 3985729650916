import { Box, Card, CardContent, Grid, Link, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { useQuery } from "@tanstack/react-query";
import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { fetchGet, LayoutContext, newGuid } from "wcz-layout";
import CustomNoRowsOverlay from "../components/common/GridCustomNoRowsOverlay";
import ProjectStatusCard from "../components/home/ProjectStatusCard";
import HeadcountSummaryModel from "../models/HeadcountSummaryModel";
import OpenItemsModel from "../models/OpenItemsModel";
import ProjectStatusCardModel from "../models/ProjectStatusCardModel";
import { apiUrl } from "../utils/BaseUrl";
import { Help } from "@mui/icons-material";
import { ProjectSettingsContext } from "../contexts/ProjectSettingsContext";

const demoData = [
    {
        employeeId: "CS1508004",
        employeeName: "Jiri Weiss",
        waitingApplications: "1 waiting application"
    },
    {
        employeeId: "CS2201005",
        employeeName: "David Sopik",
        waitingApplications: "3 waiting applications"
    },

];

export default function Home() {
    const { user } = useContext(LayoutContext);
    const { headList } = useContext(ProjectSettingsContext);
    const navigate = useNavigate();
    const isHead = useMemo(() => headList.some(head => head.employeeId === user?.id) || user.id === "C1107048", [user, headList]);     // TODO: remove yourself 

    useQuery<HeadcountSummaryModel>(["hsEmployee-HomePage"], ({ signal }) => fetchGet(`${apiUrl}/v1/hsemployee/employeeId/${user.id}`, signal), {
        enabled: !!user.id,
        retry: 1,
        onSuccess: data => navigate(`/daily-workload/${data.employeeId}`),
        onError: () => {}
    });

    const { data: openItems } = useQuery<OpenItemsModel>(["openItems-HomePage"], ({ signal }) => fetchGet(`${apiUrl}/v1/home/open-items`, signal), { enabled: !!user.id && isHead, refetchInterval: 60000, refetchOnWindowFocus: true });
    const { data: actualProjectLineStationStatusList = [] } = useQuery<ProjectStatusCardModel[]>(["actualProjectLineStationStatuses-HomePage"], ({ signal }) => fetchGet(`${apiUrl}/v1/home/actual-project-line-station-status`, signal), { enabled: !!user.id && isHead, refetchInterval: 60000, refetchOnWindowFocus: true });

    const waitingForApprovalColumns: GridColDef[] = useMemo(() => [
        { field: "employeeName", headerName: "Employee Name", width: 200 },
        {
            field: "waitingApplications", headerName: "", width: 200,
            renderCell: (params) => <Link href={`/daily-workload/${params.row.employeeId}`}>{params.value}</Link>
        },
    ] as GridColDef[], []);    

    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#f5f5f9",
            color: "rgba(0, 0, 0, 0.87)",
            maxWidth: 800,
            fontSize: theme.typography.pxToRem(12),
            border: "1px solid #dadde9",
        },
    }));

    if (!isHead) return <></>;
    
    return (
        <>   
            <Grid container padding="5px">
                <Grid item xs={11} >
                    <Typography variant="h5" color="text.secondary" sx={{ pl: "15px", pt: "15px" }} gutterBottom component="div">Open items:</Typography>
                </Grid>
                <Grid item xs={1} justifySelf="end" textAlign="right" paddingRight="7px">
                    <HtmlTooltip arrow                
                        placement="left"
                        title={
                            <Typography variant="h6">
                                Need help? <br />
                                <Link target="_blank" href="http://mfgkm-wscz.wistron.com/dcc/sopwi/_layouts/PowerPoint.aspx?PowerPointView=ReadingView&PresentationId=/dcc/sopwi/QA/WI-23-005-WSCZ-Q%20How%20to%20set%20up%20SOT%20as%201st%20APP%20when%20you%20turn%20on%20the%20computer.pptx&Source=http%3A%2F%2Fmfgkm%2Dwscz%2Ewistron%2Ecom%2Fdcc%2Fsopwi%2FQA%2FForms%2FAllItems%2Easpx%3F%26%26p%5FSortBehavior%3D0%26p%5FFileLeafRef%3DWI%252d23%252d006%2520WSCZ%252dQ%2520SOT%2520Syst%25c3%25a9m%2520User%2520Manual%2520for%2520DL%252epptx%26%26%26View%3D%7B36F51DA9%2D24F4%2D4945%2DB2FE%2D9DD69D7DC673%7D&DefaultItemOpen=1">WI-23-005-WSCZ-Q How to set up SOT as 1st APP when you turn on the computer</Link>
                                <br />
                                <Link target="_blank" href="http://mfgkm-wscz.wistron.com/dcc/sopwi/_layouts/PowerPoint.aspx?PowerPointView=ReadingView&PresentationId=/dcc/sopwi/QA/WI-23-005-01%20WSCZ-Q%20Jak%20nastavit%20SOT%20jako%201.%20APP%20p%C5%99i%20zapnut%C3%AD%20po%C4%8D%C3%ADta%C4%8De.pptx&Source=http%3A%2F%2Fmfgkm%2Dwscz%2Ewistron%2Ecom%2Fdcc%2Fsopwi%2FQA%2FForms%2FAllItems%2Easpx%3F%26%26p%5FSortBehavior%3D0%26p%5FFileLeafRef%3DWI%252d23%252d006%2520WSCZ%252dQ%2520SOT%2520Syst%25c3%25a9m%2520User%2520Manual%2520for%2520DL%252epptx%26%26PageFirstRow%3D1%26%26View%3D%7B36F51DA9%2D24F4%2D4945%2DB2FE%2D9DD69D7DC673%7D&DefaultItemOpen=1">WI-23-005-01 WSCZ-Q Jak nastavit SOT jako 1. APP při zapnutí počítače</Link>
                                <br />
                                <Link target="_blank" href="http://mfgkm-wscz.wistron.com/dcc/sopwi/_layouts/PowerPoint.aspx?PowerPointView=ReadingView&PresentationId=/dcc/sopwi/QA/WI-23-007%20WSCZ-Q%20SOT%20Syst%C3%A9m%20User%20Manual%20for%20leaders.pptx&Source=http%3A%2F%2Fmfgkm%2Dwscz%2Ewistron%2Ecom%2Fdcc%2Fsopwi%2FQA%2FForms%2FAllItems%2Easpx%3FPaged%3DTRUE%26p%5FSortBehavior%3D0%26p%5FFileLeafRef%3DWI%252d23%252d005%252dWSCZ%252dQ%2520How%2520to%2520set%2520up%2520SOT%2520as%25201st%2520APP%2520when%2520you%2520turn%2520on%2520the%2520computer%252epptx%26p%5FID%3D89%26PageFirstRow%3D31%26%26View%3D%7B36F51DA9%2D24F4%2D4945%2DB2FE%2D9DD69D7DC673%7D&DefaultItemOpen=1">WI-23-007 WSCZ-Q SOT Systém User Manual for leaders</Link>
                            </Typography>
                        }
                    >
                        <Help color="secondary" />
                    </HtmlTooltip>
                </Grid>
            </Grid>
            <Grid container spacing={1} padding="5px">
                <Grid item xs={12} sm={4} md={3} lg={2} xl={1}>
                    <Card elevation={2} sx={{ minHeight: "200px" }}>
                        <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Typography component="div" sx={{ m: 0 }} variant="h1" color="orange" gutterBottom>{openItems?.waitingForApproval}</Typography>
                            <Typography component="div" sx={{ fontSize: 14, fontWeight: 500 }} color="orange" gutterBottom>waiting for approval</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2} xl={1}>
                    <Card elevation={2} sx={{ minHeight: "200px" }}>
                        <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Typography component="div" sx={{ m: 0 }} variant="h1" color="primary" gutterBottom>{openItems?.employeesToAssign}</Typography>
                            <Typography component="div" sx={{ fontSize: 14, fontWeight: 500 }} color="primary" gutterBottom>employees to assign</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2} xl={1}>
                    <Card elevation={2} sx={{ minHeight: "200px" }}>
                        <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Typography component="div" sx={{ m: 0 }} variant="h1" color="#CC3259" gutterBottom>{openItems?.errorCases}</Typography>
                            <Typography component="div" sx={{ fontSize: 14, fontWeight: 500 }} color="#CC3259" gutterBottom>error cases</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Card sx={{ m: "5px", maxWidth: "550px", display: "none" }}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Waiting for approval (24 applications)</Typography>
                    <Box sx={{
                        display: "flex", width: "100%",
                        "& .MuiDataGrid-columnHeaderTitle": { fontWeight: "600" },
                    }}>
                        <DataGridPro columns={waitingForApprovalColumns} rows={demoData}
                            sx={{ padding: "10px 20px", border: "0px", minHeight: "200px", maxHeight: "400px" }}
                            getRowId={() => newGuid()}
                            density="compact"
                            hideFooter
                            disableRowSelectionOnClick
                            slots={{
                                noRowsOverlay: CustomNoRowsOverlay
                            }}
                            slotProps={{
                                noRowsOverlay: { noRowsMessage: "You have nothing waiting for your approval" }
                            }}

                        />
                    </Box>
                </CardContent>                
            </Card>
            {
                actualProjectLineStationStatusList.length > 0 &&
                <>
                    <Typography variant="h5" color="text.secondary" sx={{ pl: "15px", pt: "15px" }} gutterBottom component="div">Actual status by project, line and station:</Typography>
                    <Grid container spacing={1} padding="5px" justifyContent="start" alignItems="center">
                        {
                            actualProjectLineStationStatusList.map((project) => 
                                <Grid item xs={12} sm={6} lg={3} xl={2}>
                                    <ProjectStatusCard project={project} />
                                </Grid>
                            )
                        }               
                    </Grid>
                </>
            }
        </>
    );
}