import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import Department from "../models/Department";
import { fetchGet } from "wcz-layout";
import { departmentsUrl } from "../utils/BaseUrl";

const queryKey = "Departments";

export function useGetDepartments<TQueryFnData = Department[], TError = string, TData = TQueryFnData>(options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, "queryKey" | "queryFn" | "initialData">) {
    const query = useQuery([queryKey], ({ signal }) => fetchGet(departmentsUrl, signal), options);
    if (!query.data){ return query; }
    const data = (query.data as Department[]).sort((a, b) => a.departmentId.localeCompare(b.departmentId, undefined, { numeric: true, sensitivity: "base" }));
    return { ...query, data: [...data, { departmentId: "CWZXXX", description: "Company Support" } ] }
}