import { Box, Grid, IconButton, Link, TextField, Typography } from "@mui/material";
import { DataGridPro, GridCellParams, GridColDef, GridInitialState, GridScrollParams, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter, useGridApiRef } from "@mui/x-data-grid-pro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchGet, hasRole, LayoutContext, persistStorage } from "wcz-layout";
import DailyWorkloadStats from "../components/query/DailyWorkloadStats";
import DailyWorkloadStatsLegend from "../components/query/DailyWorkloadStatsLegend";
import { apiUrl } from "../utils/BaseUrl";
import { KeyboardArrowLeft, KeyboardArrowRight, KeyboardDoubleArrowRight } from "@mui/icons-material";
import { ProjectSettingsContext } from "../contexts/ProjectSettingsContext";


interface CustomToolbarProps {
    month: number,
    year: number,
    setYearAndMonth: (year: number, month: number) => void,
}

function CustomToolbar(props: CustomToolbarProps) {
    return (
        <GridToolbarContainer sx={{ marginBottom: "15px" }}>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarQuickFilter sx={{ marginLeft: "30px" }} />
            <Box sx={{ marginLeft: "auto" }}>
                {
                    moment({ year: props.year, month: props.month - 1 }) > moment('202307') &&
                    <IconButton onClick={() => {
                        var newYear = props.year;
                        var newMonth = props.month - 1;

                        if (newMonth === 0) {
                            newYear -= 1;
                            newMonth = 12;
                        }

                        props.setYearAndMonth(newYear, newMonth);
                    }}>
                        <KeyboardArrowLeft />
                    </IconButton>
                }
                <DatePicker
                    views={['year', 'month']}
                    openTo="month"
                    minDate={moment('202307')}
                    maxDate={moment()}
                    value={moment({ year: props.year, month: props.month - 1 })}
                    onChange={(newValue) => {
                        if (newValue) {
                            props.setYearAndMonth(newValue.year(), newValue.month() + 1);
                        }
                    }}
                    InputProps={{ readOnly: true }} // TODO: tato sracka nefunguje
                    renderInput={(params) => <TextField {...params} size="small" helperText={null} />}
                />
                {
                    moment({ year: props.year, month: props.month - 1 }) < moment().add(-1, "month") &&
                    <IconButton onClick={() => {
                        var newYear = props.year;
                        var newMonth = props.month + 1;

                        if (newMonth === 13) {
                            newYear += 1;
                            newMonth = 1;
                        }

                        props.setYearAndMonth(newYear, newMonth);
                    }}>
                        <KeyboardArrowRight />
                    </IconButton>
                }
                {
                    moment({ year: props.year, month: props.month - 1 }).get("month") !== moment().get("month") &&
                    moment().add(-2, "month") > moment({ year: props.year, month: props.month - 1 }) &&
                    <IconButton onClick={() => props.setYearAndMonth(moment().year(), moment().month() + 1)}>
                        <KeyboardDoubleArrowRight />
                    </IconButton>
                }
            </Box>
        </GridToolbarContainer>
    );
}

export default function Query() {
    const { user } = useContext(LayoutContext);
    const { headList } = useContext(ProjectSettingsContext);
    const navigate = useNavigate();
    const [year, setYear] = useState(!!persistStorage.get(`DatePicker-Year`) ? Number(persistStorage.get(`DatePicker-Year`)) : moment().year());
    const [month, setMonth] = useState(!!persistStorage.get(`DatePicker-Month`) ? Number(persistStorage.get(`DatePicker-Month`)) : moment().month() + 1);
    const headOf = headList.filter(head => head.employeeId === user.id);
    const isITAdmin = useMemo(() => hasRole(["wcz-developers"]), [user]);
    const hasExceptionToSeeAll = useMemo(() => user.name === "Weidi Wu", [user]);
    const { data = [] } = useQuery(["DailyWorkloadQueryEmployee", year, month], ({ signal }) => fetchGet(`${apiUrl}/v1/DailyWorkloadQueryEmployee/${year}/${month}`, signal));
    const gridApiRef = useGridApiRef();    

    function setYearAndMonth(year: number, month: number) {
        setYear(-1);
        setMonth(-1);

        setYear(year);
        setMonth(month);
        persistStorage.set(`DatePicker-Year`, year.toString());
        persistStorage.set(`DatePicker-Month`, month.toString());
    }

    function isHoliday(weekDay: number) {
        let date = `${year}-${month}-${weekDay}`;
        switch (date) {
            case "2023-1-1": return true;
            case "2023-4-7": return true;
            case "2023-4-10": return true;
            case "2023-5-1": return true;
            case "2023-5-8": return true;
            case "2023-7-5": return true;
            case "2023-7-6": return true;
            case "2023-9-28": return true;
            case "2023-10-28": return true;
            case "2023-11-17": return true;
            case "2023-12-24": return true;
            case "2023-12-25": return true;
            case "2023-12-26": return true;
            default: return false;
        }
    }

    function isWeekend(weekDay: number) {
        return moment(`${year}-${month}-${weekDay}`, "YYYY-MM-DD").weekday() === 6
            || moment(`${year}-${month}-${weekDay}`, "YYYY-MM-DD").weekday() === 0;
    }

    function getCellClassName(weekDay: number) {
        let className = '';

        let isToday = moment(`${year}-${month}-${weekDay}`, "YYYY-MM-DD").format() === moment().format();
        if (isToday) {
            className = 'is-today--cell';
        }

        if (isWeekend(weekDay)) {
            className += ' is-weekend--cell';
        }

        if (isHoliday(weekDay)) {
            className += ' is-holiday--cell';
        }

        return className;
    }

    function getHeaderClassName(weekDay: number) {
        let className = '';

        let isYesterday = moment(`${year}-${month}-${weekDay}`, "YYYY-MM-DD").format() === moment().subtract(1, 'day').format();
        if (weekDay >= 1 && isYesterday) {
            className += ' no-separator--headerCell';
        }

        let isToday = moment(`${year}-${month}-${weekDay}`, "YYYY-MM-DD").format() === moment().format();
        if (isToday) {
            className = 'is-today--cell no-separator--headerCell';
        }

        if (isWeekend(weekDay)) {
            className += ' is-weekend--cell';
        }

        if (isHoliday(weekDay)) {
            className += ' is-holiday--cell is-holiday--headerCell';
        }

        return className;
    }

    const columns: GridColDef[] = useMemo(() => [       
        { field: 'departmentId', headerName: "Department Code", width: 150 },
        { field: 'firstName', headerName: "First Name", width: 150 },
        { field: 'lastName', headerName: "Last Name", width: 150 },
        {
            field: 'employeeId', headerName: "Employee No.", width: 150,
            //renderCell: (params) => <Link onClick={() => navigate(`/daily-workload/${params.value}`)} sx={{ cursor: "pointer" }}> { params.value }</Link >
        },
        { field: 'project', headerName: "Project", width: 160 },
        { field: 'line', headerName: "Line", width: 80 },
        { field: 'station', headerName: "Station", width: 150 },
        { 
            field: 'legend', headerName: "Legend", width: 150,
            renderCell: (params) => <DailyWorkloadStatsLegend errors={params.row.errors} />,
            valueGetter: (params) => params.row.errors > 0 ? "error" : ""
        },
        {
            field: 'day1', headerName: moment(`${year}-${month}-01`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(1),
            headerClassName: () => getHeaderClassName(1),            
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 1)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 1))
        },
        {
            field: 'day2', headerName: moment(`${year}-${month}-02`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(2),
            headerClassName: () => getHeaderClassName(2),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 2)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 2))
        },
        {
            field: 'day3', headerName: moment(`${year}-${month}-03`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(3),
            headerClassName: () => getHeaderClassName(3),            
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 3)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 3))
        },
        {
            field: 'day4', headerName: moment(`${year}-${month}-04`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(4),
            headerClassName: () => getHeaderClassName(4),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 4)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 4))
        },
        {
            field: 'day5', headerName: moment(`${year}-${month}-05`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(5),
            headerClassName: () => getHeaderClassName(5),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 5)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 5))
        },
        {
            field: 'day6', headerName: moment(`${year}-${month}-06`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(6),
            headerClassName: () => getHeaderClassName(6),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 6)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 6))
        },
        {
            field: 'day7', headerName: moment(`${year}-${month}-07`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(7),
            headerClassName: () => getHeaderClassName(7),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 7)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 7))
        },
        {
            field: 'day8', headerName: moment(`${year}-${month}-08`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(8),
            headerClassName: () => getHeaderClassName(8),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 8)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 8))
        },
        {
            field: 'day9', headerName: moment(`${year}-${month}-09`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(9),
            headerClassName: () => getHeaderClassName(9),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 9)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 9))
        },
        {
            field: 'day10', headerName: moment(`${year}-${month}-10`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(10),
            headerClassName: () => getHeaderClassName(10),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 10)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 10))
        },
        {
            field: 'day11', headerName: moment(`${year}-${month}-11`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(11),
            headerClassName: () => getHeaderClassName(11),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 11)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 11))
        },
        {
            field: 'day12', headerName: moment(`${year}-${month}-12`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(12),
            headerClassName: () => getHeaderClassName(12),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 12)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 12))
        },
        {
            field: 'day13', headerName: moment(`${year}-${month}-13`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(13),
            headerClassName: () => getHeaderClassName(13),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 13)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 13))
        },
        {
            field: 'day14', headerName: moment(`${year}-${month}-14`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(14),
            headerClassName: () => getHeaderClassName(14),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 14)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 14))
        },
        {
            field: 'day15', headerName: moment(`${year}-${month}-15`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(15),
            headerClassName: () => getHeaderClassName(15),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 15)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 15))
        },
        {
            field: 'day16', headerName: moment(`${year}-${month}-16`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(16),
            headerClassName: () => getHeaderClassName(16),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 16)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 16))
        },
        {
            field: 'day17', headerName: moment(`${year}-${month}-17`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(17),
            headerClassName: () => getHeaderClassName(17),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 17)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 17))
        },
        {
            field: 'day18', headerName: moment(`${year}-${month}-18`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(18),
            headerClassName: () => getHeaderClassName(18),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 18)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 18))
        },
        {
            field: 'day19', headerName: moment(`${year}-${month}-19`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(19),
            headerClassName: () => getHeaderClassName(19),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 19)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 19))
        },
        {
            field: 'day20', headerName: moment(`${year}-${month}-20`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(20),
            headerClassName: () => getHeaderClassName(20),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 20)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 20))
        },
        {
            field: 'day21', headerName: moment(`${year}-${month}-21`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(21),
            headerClassName: () => getHeaderClassName(21),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 21)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 21))
        },
        {
            field: 'day22', headerName: moment(`${year}-${month}-22`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(22),
            headerClassName: () => getHeaderClassName(22),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 22)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 22))
        },
        {
            field: 'day23', headerName: moment(`${year}-${month}-23`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(23),
            headerClassName: () => getHeaderClassName(23),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 23)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 23))
        },
        {
            field: 'day24', headerName: moment(`${year}-${month}-24`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(24),
            headerClassName: () => getHeaderClassName(24),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 24)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 24))
        },
        {
            field: 'day25', headerName: moment(`${year}-${month}-25`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(25),
            headerClassName: () => getHeaderClassName(25),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 25)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 25))
        },
        {
            field: 'day26', headerName: moment(`${year}-${month}-26`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(26),
            headerClassName: () => getHeaderClassName(26),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 26)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 26))
        },
        {
            field: 'day27', headerName: moment(`${year}-${month}-27`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(27),
            headerClassName: () => getHeaderClassName(27),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 27)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 27))
        },
        {
            field: 'day28', headerName: moment(`${year}-${month}-28`, "YYYY-MM-DD").format("D.ddd"), width: 80,
            cellClassName: () => getCellClassName(28),
            headerClassName: () => getHeaderClassName(28),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 28)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 28))
        },
        {
            field: 'day29', headerName: moment(`${year}-${month}-29`, "YYYY-MM-DD").format("D.ddd"), width: 80, hide: !moment(`${year}-${month}-29`, "YYYY-MM-DD").isValid(),
            cellClassName: () => getCellClassName(29),
            headerClassName: () => getHeaderClassName(29),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 29)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 29))
        },
        {
            field: 'day30', headerName: moment(`${year}-${month}-30`, "YYYY-MM-DD").format("D.ddd"), width: 80, hide: !moment(`${year}-${month}-30`, "YYYY-MM-DD").isValid(),
            cellClassName: () => getCellClassName(30),
            headerClassName: () => getHeaderClassName(30),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 30)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 30))
        },
        {
            field: 'day31', headerName: moment(`${year}-${month}-31`, "YYYY-MM-DD").format("D.ddd"), width: 80, hide: !moment(`${year}-${month}-31`, "YYYY-MM-DD").isValid(),
            cellClassName: () => getCellClassName(31),
            headerClassName: () => getHeaderClassName(31),
            renderCell: (params) => <DailyWorkloadStats workloadStats={params.row.dailyStats.find((f: any) => moment(f.day).date() === 31)} />,
            valueGetter: (params) => JSON.stringify(params.row.dailyStats.find((f: any) => moment(f.day).date() === 31))
        },
    ] as GridColDef[], [year, month]);

    useEffect(() => {
        if (gridApiRef) {
            const state: GridInitialState = persistStorage.getObject(`Query-Table-state`) ?? { pinnedColumns: { left: ['departmentId', 'firstName', 'lastName', 'employeeId', 'project', 'line', 'station', 'legend'] } };
            gridApiRef.current.restoreState(state);

            let localRowIndex = persistStorage.get(`Query-Table-rowIndex`);
            let localColumnIndex = persistStorage.get(`Query-Table-columnIndex`);
            let selectRowId = gridApiRef.current.getRowIdFromRowIndex(Number(localRowIndex));
            let selectField = persistStorage.get(`Query-Table-field`);
            if (selectRowId && !!selectField) {
                gridApiRef.current.setCellFocus(selectRowId, selectField);
            }

            let localScrollPosition: GridScrollParams = persistStorage.getObject(`Query-Table-scrollPosition`) ?? { left: 0, top: 0  };
            if (localScrollPosition.left > 0 || localScrollPosition.top > 0) {
                setTimeout(() => gridApiRef.current.scroll(localScrollPosition), 200);
            }
        }
    }, [gridApiRef]);

    function handleTableStateChanged() {
        let state: GridInitialStatePro = gridApiRef.current.exportState();
        state = { ...state, preferencePanel: undefined };
        persistStorage.setObject(`Query-Table-state`, state);
    }

    function handleCellClicked(params: GridCellParams) {
        let rowIndex = gridApiRef.current.getRowIndexRelativeToVisibleRows(params.row.id);
        let columnIndex = gridApiRef.current.getColumnIndexRelativeToVisibleColumns(params.field);
        let scrollPosition = gridApiRef.current.getScrollPosition();
        persistStorage.set(`Query-Table-rowIndex`, rowIndex.toString());
        persistStorage.set(`Query-Table-columnIndex`, columnIndex.toString());
        persistStorage.set(`Query-Table-field`, params.field);
        persistStorage.setObject(`Query-Table-scrollPosition`, scrollPosition);
    }

    return (
        <Box>
            <Typography variant="h5" color="text.secondary" sx={{ pl: "25px", pt: "15px" }} gutterBottom component="div">Query & Modify</Typography>
            <Box
                sx={{
                    display: 'flex', width: '100%', maxWidth: '2580px', height: '82vh',
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: '600',
                    },
                    '& .MuiDataGrid-root .MuiDataGrid-cell': {
                        whiteSpace: 'normal!important',
                        wordBreak: 'break-word!important',
                        alignItems: 'flex-start!important',
                        paddingTop: '7px'
                    },
                    '& .is-weekend--cell': {
                        bgcolor: '#E5EDEF'
                    },
                    '& .is-holiday--cell': {
                        bgcolor: '#FFE5E5',
                    },
                    '& .is-today--cell': {
                        borderLeft: '1px solid #005363',
                        borderRight: '1px solid #005363'
                    },
                    '& .is-holiday--headerCell': {
                        color: 'red',                        
                    },
                    '& .no-separator--headerCell .MuiDataGrid-columnSeparator': {
                        display: 'none',
                    }
                }}
            >
                <DataGridPro columns={columns} rows={data.filter((d: any) => isITAdmin || hasExceptionToSeeAll || headOf.some(h => h.departmentId === d.departmentId))} density="compact" sx={{ padding: '10px 20px', margin: '0px 20px' }}
                    apiRef={gridApiRef}
                    getRowHeight={() => 130}
                    disableRowSelectionOnClick                    
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    componentsProps={{
                        toolbar: { month, year, setYearAndMonth }
                    }}
                    //initialState={persistStorage.getObject(`Query-Table-state`) ?? { pinnedColumns: { left: ['departmentId', 'firstName', 'lastName', 'employeeId', 'project', 'line', 'station', 'legend'] } }}
                    onSortModelChange={handleTableStateChanged}
                    onFilterModelChange={handleTableStateChanged}
                    onPinnedColumnsChange={handleTableStateChanged}
                    onColumnOrderChange={handleTableStateChanged}
                    onColumnVisibilityModelChange={handleTableStateChanged}
                    onColumnWidthChange={handleTableStateChanged} 
                    onCellClick={params => handleCellClicked(params)}
                    onCellDoubleClick={params => navigate(`/daily-workload/${params.row.employeeId}`)}
                />
            </Box>
        </Box>
    );
}