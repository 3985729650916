import { Card, CardHeader, Grid, IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import LineSettings from "./LineSettings";
import { Edit, LinearScale, MoreVert } from "@mui/icons-material";
import { useState } from "react";
import EditProjectDialog from "./EditProjectDialog";
import Project from "../../models/Project";
import AddLineDialog from "./AddLineDialog";

interface ProjectSettingsCardProps {
    project: Project,
}

export default function ProjectSettingsCard(props: ProjectSettingsCardProps) {
    const { project } = props;

    const [projectMenuAnchorEl, setProjectMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [openProjectMenuId, setOpenProjectMenuId] = useState<string>("");
    const handleClickOpenProjectMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setProjectMenuAnchorEl(event.currentTarget);
        setOpenProjectMenuId(event.currentTarget.id);
    };
    const handleCloseProjectMenu = () => {
        setProjectMenuAnchorEl(null);
        setOpenProjectMenuId("");
    };

    const [projectToEdit, setProjectToEdit] = useState<Project>({} as Project);
    const handleClickEditProject = () => setProjectToEdit(project);

    const [showAddLineDialog, setShowAddLineDialog] = useState(false);
    function onShowAddNewLineDialog(){ setShowAddLineDialog(true); }

    return (
        <>
            <Grid container padding="10px 20px">
                <Grid item xs={12} >
                    <Card elevation={2}>
                        <CardHeader title={project.departmentId + ": " + project.name} sx={{ bgcolor: "#99BAC0" }}
                            action={<IconButton id={`project-menu-button-${project.id}`} onClick={handleClickOpenProjectMenu}><MoreVert /></IconButton>}
                        />
                        <Menu
                            id={`project-menu-${project.id}`}
                            anchorEl={projectMenuAnchorEl}
                            open={openProjectMenuId === `project-menu-button-${project.id}`}
                            onClose={handleCloseProjectMenu}
                        >
                            <MenuItem onClick={handleClickEditProject}>
                                <ListItemIcon>
                                    <Edit fontSize="small" />
                                </ListItemIcon>
                                Edit project
                            </MenuItem>
                            <MenuItem onClick={onShowAddNewLineDialog}>
                                <ListItemIcon>
                                    <LinearScale fontSize="small" />
                                </ListItemIcon>
                                Add new line
                            </MenuItem>
                        </Menu>
                        {
                            project.lines.map((line) => <LineSettings key={line.id} line={line} />)
                        }
                    </Card>
                </Grid>
            </Grid>
            
            {projectToEdit && <EditProjectDialog project={project} projectToEdit={projectToEdit} setProjectToEdit={setProjectToEdit} />}
            <AddLineDialog project={project} open={showAddLineDialog} setOpen={setShowAddLineDialog} />
        </>
    );
}