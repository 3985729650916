import { AccessTime, Groups, Home as HomeIcon, Preview, Settings, Summarize, TaskAlt } from "@mui/icons-material";
import { cyan, purple } from "@mui/material/colors";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useEffect, useMemo, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { fetchGet, hasRole, LayoutProvider, LeftDrawerItem, useUser } from "wcz-layout";
import packageJson from "../package.json";
import PrivateRoute from "./components/common/PrivateRoute";
import DailyWorkload from "./pages/DailyWorkload";
import HeadcountSummary from "./pages/HeadcountSummary";
import Home from "./pages/Home";
import Query from "./pages/Query";
import ProjectSettings from "./pages/ProjectSettings";
import { apiUrl } from "./utils/BaseUrl";
import { ProjectSettingsProvider } from "./contexts/ProjectSettingsContext";
import Project from "./models/Project";


export default function App() {
    const user = useUser();
    const { pathname } = useLocation();
    const isITAdmin = useMemo(() => hasRole(["wcz-developers"]), [user]);

    const [projectList, setProjectList] = useState<Project[]>([]);

    useEffect(() => {
        if (user.name){
            fetchGet(`${apiUrl}/v1/project`, undefined)
                .then(data => {
                    setProjectList(data);
                });
        }
    }, [user]);

    const isHead = useMemo(() => {
        return projectList
            .some(project => project.lines
                .some(line => line.leaders
                    .some(leader => leader.employeeId === user?.id)
                )
            );
    }, [user, projectList]);

    const leftDrawerItems: LeftDrawerItem[] = useMemo(() => [
        { title: "Home", path: "/", icon: <HomeIcon fontSize="large" />, hidden: !isHead && !isITAdmin && !pathname.endsWith("/") },
        { title: "Headcount Summary", path: "/headcount-summary", icon: <Groups fontSize="large" />, hidden: !isHead && !isITAdmin },
        { title: "Workload Update", path: `/daily-workload/${isITAdmin ? "CS1204003" : user?.id}`, icon: <AccessTime fontSize="large" />, hidden: isHead && !pathname.includes("daily-workload") },
        { title: "Query & Modify", path: "/query-modify", icon: <Preview fontSize="large" />, hidden: !isHead && !isITAdmin },
        { title: "Report", path: "/.", icon: <Summarize fontSize="large" />, hidden: !isHead && !isITAdmin },
        { title: "Settings", path: "/settings", icon: <Settings fontSize="large" />, hidden: !isHead && !isITAdmin, divider: true },
        { title: "E-checklist", path: "https://sms-checklist.wistron.eu/", icon: <TaskAlt htmlColor={cyan[600]} fontSize="large" /> },
    ], [user, isHead, isITAdmin, pathname]);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <LayoutProvider title={packageJson.appTitle} appVersion={packageJson.version} primaryColor={"#005363"} secondaryColor={purple[500]} leftDrawerItems={leftDrawerItems}>
                <ProjectSettingsProvider projectList={projectList}>
                    <Routes>
                        <Route path='/' element={
                            <PrivateRoute>                                                       
                                <Home />
                            </PrivateRoute>
                        } />                    
                        <Route path='/headcount-summary' element={
                            <PrivateRoute>
                                <HeadcountSummary />                            
                            </PrivateRoute>
                        } />
                        <Route path='/daily-workload/:employeeId' element={
                                <PrivateRoute>
                                <DailyWorkload />                                                       
                            </PrivateRoute>
                        } />
                        <Route path='/query-modify' element={
                            <PrivateRoute>
                                <Query />
                            </PrivateRoute>
                        } />
                        <Route path='/settings' element={
                            <PrivateRoute>
                                <ProjectSettings />
                            </PrivateRoute>
                        } />
                    </Routes>
                </ProjectSettingsProvider>
            </LayoutProvider>
        </LocalizationProvider>
    );
}