import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { LayoutContext } from "wcz-layout";
import ProjectSettingsCard from "../components/settings/ProjectSettingsCard";
import { useGetProjects } from "../queries/ProjectSettingsQueries";
import Project from "../models/Project";
import { Add } from "@mui/icons-material";
import AddProjectDialog from "../components/settings/AddProjectDialog";


export default function ProjectSettings() {
    const { user } = useContext(LayoutContext);
    const { data: projectList = [] } = useGetProjects({ enabled: !!user.name });

    const [showAddProjectDialog, setShowAddProjectDialog] = useState(false);
    function openAddProjectDialog(){ setShowAddProjectDialog(true); }

    return (
        <Box>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-end" mb={0.5} px="20px" pt="25px">
                <Typography variant="h5" color="text.secondary" sx={{ mb: 0 }} gutterBottom component="div">Project Settings</Typography>
                <Chip label="Add Project" icon={<Add />} onClick={openAddProjectDialog} />
            </Stack>
            {
                projectList?.map((project: Project) => <ProjectSettingsCard key={project.id} project={project} />)
            }

            <AddProjectDialog open={showAddProjectDialog} setOpen={setShowAddProjectDialog} />
        </Box>
      );
}